import React from "react"
import "../static/style/pageStyle/demoPage.scss"



import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby";
import { demoPageData, FetchGetStarted } from "../services/helperFunctions";
import BannerHeader from "../components/bannerHeader";
import { ErrorMessage, Field, Form, Formik } from "formik"
import { toast } from 'react-toastify';
import { navigate } from "gatsby";
import FinancialServiceSlider from "../components/financial-service-slider";
import Underline from "../components/underline";


const Benefits = props => {
    const data = useStaticQuery(
        graphql`
      {
        allPryzmDemo {
            nodes {
              pryzmDemo {
                journey {
                  attributes {
                    cmpName
                    heading
                    subheading
                    dynamicCmp {
                      imageUrl
                      textcardHeading
                      textcardParagraph
                    }
                  }
                }
              }
            }
          }
      }
    `
    )

    const title = "Benefits | Pryzm - A Data Reliability & Observability Platform";
    const {
        banner,
        section1,
    } = demoPageData(data);
    const { countries } = FetchGetStarted();
    const encode = data => {
        return Object.keys(data)
            .map(key => {
                if (key === 'country') {
                    const countryLabel = countries.find(country => country.value === data[key])?.label || '';
                    return encodeURIComponent(key) + "=" + encodeURIComponent(countryLabel);
                }
                return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
            })
            .join("&");
    }
    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: "en",
                }}
            >
                <title>{title}</title>
            </Helmet>
            <div className={`platform-v3 demo-page`}>
                <div className="page-content">
                    <div className="banner">
                        <div className="page-wrapper">
                            <div className="heading">
                                <BannerHeader
                                    header={banner.heading}
                                    line={"single-line"}
                                    headingType="bannerTitle"
                                />
                                <p>{banner.subheading}</p>
                            </div>
                            <div className="content">

                                <div className="text-content">
                                    <h2>{banner.dynamicCmp[0].textcardHeading}</h2>
                                    <div className="para">
                                        {
                                            banner.dynamicCmp[0].textcardParagraph.split("*").map((item, index) => (
                                                <p>{item}</p>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="form-content">
                                    <div className="wrapper contact_form">
                                        <Formik
                                            initialValues={{
                                                name: "",
                                                email: "",
                                                mobile: "",
                                                message: "",
                                            }}
                                            onSubmit={(values, actions) => {
                                                fetch(`https://lumiq.ai/access`, {
                                                    method: "POST",
                                                    headers: {
                                                        "Content-Type": "application/x-www-form-urlencoded",
                                                    },
                                                    body: encode({ "form-name": "contactForm", ...values, web:"pryzm" }),
                                                })
                                                    .then(() => {
                                                        toast.success('Your response has been recorded successfully. We\'ll reach out to you!', {
                                                            position: toast.POSITION.TOP_CENTER,
                                                        });
                                                        actions.resetForm();
                                                        navigate("/success-page");
                                                        // handleIsOpenModal(isModalOpen)
                                                    })
                                                    .catch(() => {
                                                        toast.error('Message failed to send.', {
                                                            position: toast.POSITION.TOP_CENTER,
                                                        });
                                                    })
                                                    .finally(() => actions.setSubmitting(false))
                                            }}
                                            validate={values => {
                                                const nameRegex = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/
                                                const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}/
                                                const errors = {}
                                                if (!values.name || !nameRegex.test(values.name)) {
                                                    errors.name = "Valid Name Required"
                                                }
                                                if (!values.email || !emailRegex.test(values.email)) {
                                                    errors.email = "Valid Email Required"
                                                }
                                                if (!values.company) {
                                                    errors.company = "Company Name Required"
                                                }
                                                if (values.mobile) {
                                                    if (!mobileRegex.test(values.mobile)) {
                                                        errors.mobile = "Invalid Mobile Number"
                                                    }
                                                }
                                                if (!values.mobile) {
                                                    errors.mobile = "Valid Mobile Number Required"
                                                }
                                                return errors
                                            }}
                                        >
                                            {() => (
                                                <Form name="contactForm" data-netlify={true}>

                                                    <div className=" get-started-form-field">
                                                        <label className="relative">
                                                            <span className="error">
                                                                <ErrorMessage name="name" />
                                                            </span>
                                                            <Field
                                                                name="name"
                                                                className="input-text"
                                                                placeholder="Full Name*"
                                                            />

                                                        </label>
                                                        <label className="relative">
                                                            <span className="error">
                                                                <ErrorMessage name="email" />
                                                            </span>
                                                            <Field
                                                                name="email"
                                                                className="input-text"
                                                                placeholder="Business Email*"
                                                            />

                                                        </label>
                                                        {/* <div className="email-mobile"> */}
                                                        <label className="relative">
                                                            <span className="error">
                                                                <ErrorMessage name="company" />
                                                            </span>
                                                            <Field
                                                                name="company"
                                                                className="input-text"
                                                                placeholder="Company Name*"
                                                            />

                                                        </label>
                                                        <label className="relative">
                                                            <span className="error">
                                                                <ErrorMessage name="mobile" />
                                                            </span>
                                                            <Field
                                                                name="mobile"
                                                                className="input-text"
                                                                placeholder="Contact Number*"
                                                            />
                                                        </label>

                                                        {/* <label className="relative">
                                                            <span className="error">
                                                                <ErrorMessage name="region" />
                                                            </span>
                                                            <Field
                                                                name="region"
                                                                className="input-text"
                                                                placeholder="Region"
                                                            />

                                                        </label> */}

                                                        {/* </div> */}
                                                        {/* <label className="country-dropdown">
                            <Select
                              name="country"
                              className="input-text"
                              placeholder="COUNTRY"
                              options={countries}
                              isClearable={true}
                            />
                          </label> */}
                                                        <label className="label-get-started-textarea">
                                                            {/* <p>
                                                                Tell us about your project, a bit of context will allow us to connect you to the right team faster:
                                                            </p> */}
                                                            <Field
                                                                name="message"
                                                                className="get-started-textarea"
                                                                component="textarea"
                                                                placeholder="Please share anything that will help prepare for our meeting."
                                                            />
                                                        </label>
                                                        <label className="">
                                                            <button type="" className="btn-submit">
                                                            Book A Demo
                                                            </button>
                                                        </label>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Underline />
                    <FinancialServiceSlider />
                    <Underline />
                    <div className="partner">
                        <div className="page-wrapper">
                            <BannerHeader
                                header={section1.heading}
                                line={"single-line"}
                                headingType="bannerTitle"
                            />
                            <div className="image-container">
                                {section1.dynamicCmp.map((item, index) => (
                                    <div className="images">
                                        <img src={`${process.env.STRAPI_URL + item.imageUrl}`} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <Underline />
                </div>

            </div>
        </>
    )
}
export default Benefits
